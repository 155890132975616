
import logo from './logo.svg';
import './App.css';


function App() {
  return (
    <div className="App">
      <div className="slider">
        <div clclassNameass="slide"></div>
        <div className="slide"></div>
        <div className="slide"></div>
        <div className="slide"></div>
        <div className="slide"></div>
      </div>

      <div className="container">
        <h1>YAPIM AŞAMASINDA</h1>
       <div className="row">
            <img src={logo} className="logo" alt="logo" />
        <b>
          oda<span>kiralık</span>
        </b>
        </div>
      </div>
    </div>

  );
}

export default App;
